import { Box } from '@chakra-ui/react';
import BaseCarousel from '@/components/Common/BaseCarousel';
import { RankItemList } from '@/components/Common/ListItem/Rank';
import { ChartPreviewFragmentFragment } from '@/graphql/generated/schema';
import { SectionLayout } from '../../Layout/SectionLayout';
import React from 'react';

export type NonFalsy<T> = Exclude<Exclude<T, undefined>, null>;

const ChartEndMap = {
  latestRank: 99,
  chineseRank: 1,
  koreanRank: 5,
  globalRank: 3,
  japanRank: 4,
  taiwanRank: 2,
  ostRank: 6,
  rapRank: 12,
  weeklyRank: 499,
  yearRank: 599,
  chartShareRank: 'chartShareRank',
} as const;

interface ITopRankChartProps {
  data: ChartPreviewFragmentFragment | null | undefined;
  chartName: keyof typeof ChartEndMap;
  isNotFirstChart: boolean;
}

function generateRankItem(
  chartName: string,
  tracks: NonFalsy<ChartPreviewFragmentFragment['tracks']>
) {
  const result = [];

  for (let i = 0; i < 20; i++) {
    const list = [];

    const index = 5 * i;

    if (index >= tracks.length) {
      break;
    }

    for (let j = 0; j < 5; j++) {
      if (index + j >= tracks.length) {
        break;
      }
      list.push(
        <RankItemList
          key={`${chartName}Item${index + j}`}
          isLined={j === 4}
          rank={index + j}
          status={tracks[index + j]?.status}
          trackId={tracks[index + j].trackInfo.trackId}
          trackTitle={tracks[index + j].trackInfo.trackTitle}
          artist={tracks[index + j].trackInfo.artist}
          imageUrl={tracks[index + j].trackInfo.album?.imageUrl}
        />
      );
    }
    result.push(
      <Box key={`${chartName}${i}`} paddingRight="20px">
        {list}
      </Box>
    );
  }

  return result;
}

function RankChart({
  data,
  chartName,
  isNotFirstChart = true,
}: ITopRankChartProps) {
  return data ? (
    <SectionLayout
      id={chartName}
      sectionTitle={data?.title || ''}
      isBordered={isNotFirstChart}
      href={`/chart/${ChartEndMap[chartName]}`}
    >
      <BaseCarousel variant="chart" containerId={chartName} totalNum={20}>
        {data?.tracks && generateRankItem(chartName, data?.tracks)}
      </BaseCarousel>
    </SectionLayout>
  ) : (
    <></>
  );
}

export const RankChartMemo = React.memo(RankChart);

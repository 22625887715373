import { Box, Image, keyframes } from '@chakra-ui/react';
import { getMediator, StatusWidget } from '@/utils/Mediator';
import { ButtonElement } from './ButtonElement';

const scroll40 = keyframes`
  to {background-position-x: -3600px;}
`;

const scroll50 = keyframes`
  to {background-position-x: -4500px;}
`;

const animation40 = `${scroll40} 2s steps(90) infinite`;

const animation50 = `${scroll50} 2s steps(90) infinite`;

interface IAuditionButtonProps {
  trackId: number;
  imageUrl?: string | null;
}

export function AuditionButton({ trackId, imageUrl }: IAuditionButtonProps) {
  let widget: StatusWidget;

  const handleClick = () => {
    if (!widget) {
      widget = new StatusWidget(
        getMediator(),
        trackId,
        new ButtonElement(trackId)
      );
    }
    widget?.handleClick();
  };

  return (
    <Box
      display="table-cell"
      width={{ base: '50px', md: '40px' }}
      verticalAlign="middle"
      pos="relative"
      sx={{
        '.icon_play:hover': {
          background: 'rgba(0,0,0,.8)',
          _before: {
            content: '""',
            position: 'absolute',
            top: 'calc(50% - 9px)',
            left: 'calc(50% - 7px)',
            borderStyle: 'solid',
            borderWidth: '9px 0 9px 14px',
            borderColor: 'transparent transparent transparent white',
            transition: '100ms all ease',
          },
        },
        '.icon_play.on': {
          background:
            'url(/playing_white_40x40.png) 0 0 no-repeat rgba(0,0,0,.8)',
          backgroundSize: { base: '4500px 50px', md: '3600px 40px' },
          animation: { base: animation50, md: animation40 },
        },
        '.icon_play.on:hover': {
          background: 'rgba(0,0,0,.8)',
          _before: {
            height: '18px',
            borderStyle: 'double',
            borderWidth: '0px 0 0px 14px',
          },
        },
      }}
    >
      <Image
        src={imageUrl || '/default_album.png'}
        alt="專輯封面"
        boxSize={{ base: '50px', md: '40px' }}
        loading="lazy"
      />
      <Box
        id={String(trackId)}
        className="icon_play"
        boxSize={{ base: '50px', sm: '40px' }}
        pos="absolute"
        top="0px"
        onClick={handleClick}
      />
    </Box>
  );
}

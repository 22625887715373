export class ButtonElement {
  private iconElement: HTMLElement;
  constructor(trackId: number) {
    this.iconElement = document.getElementById(String(trackId)) as HTMLElement;
  }

  public setPlaying() {
    this.iconElement.classList.add('on');
  }

  public setPaused() {
    this.iconElement.classList.remove('on');
  }

  public reset() {
    this.iconElement.classList.remove('on');
  }
}

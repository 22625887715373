import { Box } from '@chakra-ui/react';

interface IRankNumberProps {
  status: string;
  index: number;
  type: 'short' | 'long';
}

const cssStyle = {
  short: {
    container: {
      display: 'table-cell',
      verticalAlign: 'top',
      w: '38px',
      pl: '13px',
    },
    number: {
      fontSize: { md: '14px', base: '17px' },
      lineHeight: { md: '20px', base: '28px' },
    },
    sign: {
      w: '25px',
      h: '20px',
      bgPos: '8px 7px',
    },
  },
  long: {
    container: {
      display: 'flex',
      flexDirection: { xl: 'row', base: 'column' },
      justifyContent: 'center',
      alignItems: 'center',
      w: { xl: 'fit-content', sm: '38px', base: '28px' },
      pl: { xl: '0px', base: '8px' },
    },
    number: {
      fontSize: '14px',
      lineHeight: '20px',
      w: { xl: '30px' },
      textAlign: { xl: 'right' },
    },
    sign: {
      w: '15px',
      h: '20px',
      bgPos: { xl: '5px 8px', base: '3px 7px' },
    },
  },
} as const;

export function RankNumber({ status, index, type }: IRankNumberProps) {
  return (
    <Box {...cssStyle[type].container} textAlign="center">
      <Box color="black" {...cssStyle[type].number}>
        {index + 1}
      </Box>
      <Box
        h="20px"
        _after={{
          bg: `url(/Rank/${status}.svg) no-repeat`,
          content: '""',
          ...cssStyle[type].sign,
          display: 'inline-block',
        }}
      />
    </Box>
  );
}

import { useEffect, useState } from 'react';

type ObserverConfig = { subjectId: string; index: number }[];

function useChartCollection(config: ObserverConfig) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index === config.length - 1) {
      return;
    }
    const node = document.getElementById(
      config[index].subjectId
    ) as HTMLElement;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          }
          setIndex(index + 1);
        });
      },
      { threshold: 1 }
    );

    observer.observe(node);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(node);
    };
  }, [config, index]);

  return config[index].index;
}

export default useChartCollection;

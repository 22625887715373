import Head from 'next/head';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export interface IHeaderProps {
  data?: {
    tabTitle: string | null;
    title: string;
    'og:title'?: string;
    description: string;
    'og:description'?: string;
    keywords: string;
    path: string;
    imageUrl?: string;
  };
}

export function Header({ data }: IHeaderProps) {
  let url = '';

  data && (url = `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}${data.path}`);

  return (
    <Head>
      <title>{data?.tabTitle || 'LINE MUSIC'}</title>
      <link rel="icon" type="image/x-icon" href="/favicon.png" />
      {data && (
        <>
          <meta name="title" content={data.title} />
          <meta property="og:title" content={data['og:title'] || data.title} />
          <meta name="description" content={data.description} />
          <meta
            property="og:description"
            content={data['og:description'] || data.description || data.title}
          />
          <meta name="keywords" content={data.keywords} />
          <meta property="og:url" content={url} />
          {data.imageUrl && (
            <meta property="og:image" content={data.imageUrl} />
          )}
          <link rel="canonical" href={url} />
          <link
            rel="alternate"
            href={url}
            media="only screen and (max-width:640px)"
          />
          <link rel="alternate" href={url} media="handheld" />
        </>
      )}
    </Head>
  );
}

import ErrorModal from '@/components/Modal/ErrorModal';
import { getAxiosInstance } from '@/libs/axios';
import { AuditionMediator, EventType } from '../AuditionMediator';
import { modalStore } from '@/utils/Stores/Modal';
import { Player } from './Player';

const axios = getAxiosInstance();

async function getAuditionUrl(trackId: number) {
  const { data } = await axios.get(`/api/audio/v1/link/${trackId}`);

  return data.link;
}

// async function getTrackUrl(trackId: number, player: Player) {
//   try {
//     const { data } = await axios.get('/api/v1/BGMStPlay', {
//       params: { playtrackId: trackId },
//     });

//     const parsedData = await parseStringPromise(data);

//     const trackUrl = parsedData.data[1].message.result[0].trackPlayUrl[0];

//     player.load(trackUrl);
//   } catch (e) {
//     openModal(ErrorModal, {}, true);
//   }
// }

export interface PlayStrategy {
  load(player: Player): void;
  setTrack(trackId: number | number[]): PlayStrategy;
  onEnded?: (player: Player) => void;
  onTimeUpdated?: (player: Player) => void;
}

export class SingleStatusWhole implements PlayStrategy {
  protected mediator: AuditionMediator;
  private trackId!: number;

  constructor(mediator: AuditionMediator) {
    this.mediator = mediator;
  }

  public setTrack(trackId: number) {
    this.trackId = trackId;

    return this;
  }

  public async load(player: Player) {
    try {
      const link = await getAuditionUrl(this.trackId);

      player.load(link);
    } catch (e) {
      modalStore.openModal(ErrorModal, {}, true);
    }
  }

  public onEnded = () => {
    this.mediator.nofity({ type: EventType.END });
  };
}

export class SingleProgressWhole
  extends SingleStatusWhole
  implements PlayStrategy
{
  constructor(mediator: AuditionMediator) {
    super(mediator);
  }

  public onTimeUpdated = (player: Player) => {
    this.mediator.nofity({
      type: EventType.PLAYING,
      payload: { percent: player.currentTime / 30 },
    });
  };
}

// export class MultipleStatusWhole {
//   static setUpTrack(mediator: Mediator, player: Player, trackIds: number[]) {
//     let index = 0;

//     player.onEnded = () => {
//       index += 1;
//       if (trackIds.length === index) {
//         mediator.nofity(EventType.END);
//       }

//       getAuditionUrl(trackIds[index], player);
//     };
//     getAuditionUrl(trackIds[index], player);
//   }
// }

// interface TrackDuration {
//   startTime: number;
//   endTime: number;
// }

// export class SingleProgressPartial {
//   static async setUpTrack(
//     mediator: Mediator,
//     player: Player,
//     trackId: number,
//     editable: boolean,
//     { startTime, endTime }: TrackDuration
//   ) {
//     player.onTimeUpdated = () => {
//       if (player.currentTime >= endTime) {
//         player.pause();
//         mediator.nofity(EventType.END);
//       }
//       mediator.nofity(EventType.PLAYING);
//     };
//     if (editable) {
//       await getTrackUrl(trackId, player);
//     } else {
//       await getAuditionUrl(trackId, player);
//     }
//     player.currentTime = startTime;
//   }
// }

import { Box } from '@chakra-ui/react';
import { ItemInfo, generateSubtitles } from '../parts/ItemInfo';
import { AuditionButton } from './AuditionButton';
import { RankNumber } from '../parts/RankNumber';
interface IRankItemListProps {
  isLined: boolean;
  status: string;
  rank: number;
  trackId: number;
  trackTitle: string;
  artist?: {
    artistName: string;
    artistId: number;
  } | null;
  imageUrl?: string;
}

export function RankItemList(props: IRankItemListProps) {
  return (
    <Box
      height={{ md: '56px', base: '60px' }}
      padding={{ md: '8px 0', base: '5px 0' }}
      position="relative"
      _after={{
        content: props.isLined ? 'none' : '""',
        borderBottom: '1px solid hsla(0,0%,89.4%,.6)',
        position: 'absolute',
        width: 'calc(100% - 20px)',
        left: '10px',
        bottom: '0px',
      }}
    >
      <Box
        display="table"
        height={{ base: '50px', md: '40px' }}
        width="100%"
        sx={{ tableLayout: 'fixed' }}
      >
        <AuditionButton trackId={props.trackId} imageUrl={props.imageUrl} />
        <RankNumber status={props.status} index={props.rank} type="short" />
        <ItemInfo
          title={props.trackTitle}
          titleUrl={`/track/${props.trackId}`}
          subtitles={generateSubtitles(props.artist)}
          styles={{
            display: 'table-cell',
            verticalAlign: 'top',
            padding: '0 20px 0 12px',
          }}
        />
      </Box>
    </Box>
  );
}

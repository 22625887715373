import {
  PlayStrategy,
  SingleStatusWhole,
  SingleProgressWhole,
  // MultipleStatusWhole,
  // SingleProgressPartial,
  Player,
} from './Player';

export interface Widget {
  type: string;
  track: number[] | number;
  reset(): void;
  update?: (currentTime: number) => void;
}

export enum EventType {
  PLAY,
  PLAYING,
  PAUSE,
  RESUME,
  END,
}

interface EventPayload {
  [EventType.PLAY]: { widget: Widget };
  [EventType.PLAYING]: { percent: number };
  [EventType.PAUSE]: undefined;
  [EventType.RESUME]: undefined;
  [EventType.END]: undefined;
}

type EventMap<M extends EventPayload> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type Event = EventMap<EventPayload>[keyof EventMap<EventPayload>];

export class AuditionMediator {
  private player: Player;
  private stategies: { [key: string]: PlayStrategy };
  private widget!: Widget;

  constructor() {
    this.player = new Player();
    this.stategies = {
      SingleStatusWhole: new SingleStatusWhole(this),
      SingleProgressWhole: new SingleProgressWhole(this),
    };
  }

  public nofity(event: Event) {
    switch (event.type) {
      case EventType.PLAY: {
        const newWidget = event.payload.widget;

        this.player.play(
          this.stategies[newWidget.type].setTrack(newWidget.track)
        );

        if (this.widget !== newWidget) {
          this.widget?.reset();
          this.widget = newWidget;
        }
        break;
      }
      case EventType.PLAYING:
        this.widget.update?.(event.payload.percent);
        break;
      case EventType.PAUSE:
        this.player.pause();
        break;
      case EventType.RESUME:
        this.player.resume();
        break;
      case EventType.END:
        this.player.reset();
        this.widget?.reset();
        break;
    }
  }

  public clear() {
    this.player.pause();
  }
}

import { Box, Text, BoxProps } from '@chakra-ui/react';
import Link from 'next/link';

export type Subtitles =
  | {
      str: string;
      url?: string;
    }[];

interface IItemInfoProps {
  title: string;
  titleUrl: string;
  subtitles?: Subtitles;
  styles: BoxProps;
}

export function ItemInfo({
  title,
  titleUrl,
  subtitles = [],
  styles,
}: IItemInfoProps) {
  return (
    <Box textAlign="left" {...styles}>
      <Text
        className="ellipsis_text one_line"
        fontSize={{ md: '14px', base: '17px' }}
        height={{ md: '20px', base: '24px' }}
        color="black"
      >
        <Link href={titleUrl}>{title}</Link>
      </Text>
      {subtitles.length > 0 && (
        <Text
          className="ellipsis_text one_line"
          fontSize={{ md: '13px', base: '15px' }}
          height={{ md: '18px', base: '22px' }}
          color="gray.7"
        >
          {subtitles.map(({ str, url }, index) => {
            const _title = `${index === 1 ? '/ ' : ''}${str}`;

            if (url) {
              return (
                <Link key={url} href={url}>
                  {_title}
                </Link>
              );
            }

            return _title;
          })}
        </Text>
      )}
    </Box>
  );
}

interface Artist {
  artistName: string;
  artistId: number | string;
}

interface Album {
  albumId: number;
  albumTitle: string;
}

export function generateSubtitles(
  artist?: Artist | null,
  album?: Album | null
) {
  const subtitles = [];

  artist &&
    subtitles.push({
      str: artist.artistName,
      url: `/artist/${artist.artistId}`,
    });

  album &&
    subtitles.push({
      str: album.albumTitle,
      url: `/album/${album.albumId}`,
    });

  return subtitles;
}

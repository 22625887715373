import { PlayStrategy } from './PlayStrategy';

export class Player {
  private shouldStopPlaying = false;
  private trackInstance: HTMLAudioElement;
  private strategy: PlayStrategy | null = null;

  constructor() {
    this.trackInstance = new Audio();
    this.trackInstance.oncanplay = () => {
      if (this.shouldStopPlaying) {
        return;
      }
      this.trackInstance.play();
    };
    // this.trackInstance.onerror = (e) => {
    //   console.log('error', e);
    // }
    this.trackInstance.onended = () => {
      this.strategy?.onEnded?.(this);
    };
    this.trackInstance.ontimeupdate = () => {
      this.strategy?.onTimeUpdated?.(this);
    };
  }

  public load(trackUrl: string) {
    this.trackInstance.src = trackUrl;
    this.trackInstance.load();
  }

  public play(strategy: PlayStrategy) {
    this.shouldStopPlaying = false;
    this.trackInstance.src = '';
    this.strategy = strategy;
    this.strategy.load(this);
  }

  public pause() {
    this.shouldStopPlaying = true;
    if (!this.trackInstance.paused) {
      this.trackInstance.pause();
    }
  }

  public resume() {
    this.shouldStopPlaying = false;
    this.trackInstance.play();
  }

  public reset() {
    this.strategy = null;
    this.shouldStopPlaying = false;
    this.trackInstance.src = '';
  }

  public get currentTime() {
    return this.trackInstance.currentTime;
  }
}

import { type Widget, AuditionMediator, EventType } from './AuditionMediator';

enum Status {
  PLAYING,
  PAUSED,
  ENDED,
}

interface WidgetElement {
  setPlaying(): void;
  setPaused(): void;
  update?(percent: number): void;
  reset(): void;
}

type WidgetType = 'SingleStatusWhole' | 'SingleProgressWhole';

class BasicWidget implements Widget {
  private _type: WidgetType;
  private status = Status.ENDED;

  private mediator: AuditionMediator;
  private _trackId: number | number[];
  protected element: WidgetElement;

  constructor(
    mediator: AuditionMediator,
    type: WidgetType,
    trackId: number | number[],
    element: WidgetElement
  ) {
    this.mediator = mediator;
    this._type = type;
    this._trackId = trackId;
    this.element = element;
  }

  public handleClick = () => {
    if (this.status === Status.ENDED) {
      this.element.setPlaying();
      this.status = Status.PLAYING;
      this.mediator.nofity({ type: EventType.PLAY, payload: { widget: this } });
    } else if (this.status === Status.PLAYING) {
      this.element.setPaused();
      this.status = Status.PAUSED;
      this.mediator.nofity({ type: EventType.PAUSE });
    } else if (this.status === Status.PAUSED) {
      this.element.setPlaying();
      this.status = Status.PLAYING;
      this.mediator.nofity({ type: EventType.RESUME });
    }
  };

  public reset() {
    if (this.status === Status.ENDED) {
      return;
    }
    this.element.reset();
    this.status = Status.ENDED;
  }

  public get type() {
    return this._type;
  }
  public get track() {
    return this._trackId;
  }
}

export class StatusWidget extends BasicWidget {
  constructor(
    mediator: AuditionMediator,
    trackId: number | number[],
    element: WidgetElement
  ) {
    super(mediator, 'SingleStatusWhole', trackId, element);
  }
}

export class ProgressWidget extends BasicWidget {
  constructor(
    mediator: AuditionMediator,
    trackId: number | number[],
    element: WidgetElement
  ) {
    super(mediator, 'SingleProgressWhole', trackId, element);
  }

  public update(percent: number) {
    this.element.update?.(percent);
  }
}

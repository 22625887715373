import { AuditionMediator } from './AuditionMediator';

let mediator: AuditionMediator | null = null;

export function initMediator() {
  mediator = new AuditionMediator();
}

export function getMediator() {
  if (!mediator) {
    throw new Error('mediator is not yet inited');
  }

  return mediator;
}

export function clearMediator() {
  mediator?.clear();
  mediator = null;
}

import { useEffect } from 'react';
import { clearMediator, initMediator } from '@/utils/Mediator';
import { useRouter } from 'next/router';

function usePlayerMediator() {
  const { asPath } = useRouter();

  useEffect(() => {
    initMediator();

    return () => {
      clearMediator();
    };
  }, [asPath]);
}

export { usePlayerMediator };
